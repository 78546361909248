import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from "mui-color-input";
import EditIcon from "@mui/icons-material/Edit";

import { SponsorDoc, SponsorsDict } from "../../types/sponsors";
import { ref, getDownloadURL, uploadString, deleteObject, getMetadata } from "firebase/storage";

import { storage } from "../../types/firebase";
import { isEMailValid, isNameValid, urlAppendCacheBuster } from "../../utils/utils";

import header_Image from "../../ressources/global workforce_thin font - large.png";
import checked_background from "../../ressources/checked_background2.png";

import { TitledPage } from "../../components/titled-page";
import { deleteSponsor, loadSponsors, storeSponsor } from "../../data-functions/system-data_api";
import { AuthContext } from "../../components/auth-provider";
import { isEqual } from "lodash";
import { AddressInput } from "../../components/address-input";
import {
  AddressErrors,
  Country,
  EMPTY_ADDRESS,
  EMPTY_ADDRESS_ERRORS,
  EMPTY_COUNTRY,
} from "../../types/commons";

const SponsorsData: React.FC = () => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [selectedSponsor, setSelectedSponsor] = useState<SponsorDoc | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageFileExtension, setImageFileExtension] = useState<string>("");

  const [errors, setErrors] = useState<{
    name: string;
    email: string;
    address: AddressErrors;
    licenseNumber: string;
    color: string;
  }>({ name: "", email: "", address: EMPTY_ADDRESS_ERRORS, licenseNumber: "", color: "" });

  const { currentUser } = useContext(AuthContext)!;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);

    setSponsors(sponsorsDict);
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  useEffect(() => {
    fetchSponsors();
  }, [currentUser]);

  const handleAddSponsor = async () => {
    const newSponsor = { name: "New Sponsor" };
    // const newId = await storeSponsor({ id: "", newSponsor }, true);
    setSelectedSponsor({ id: "", sponsor: newSponsor });
    setImageSrc(null);
  };

  const handleSaveSponsor = async () => {
    if (!validate()) {
      return;
    }

    // Check if document exists
    if (selectedSponsor) {
      let newSponsor: SponsorDoc | null = { ...selectedSponsor };

      if (newSponsor.id === "") {
        newSponsor = await storeSponsor(currentUser?.appUser, newSponsor, true);
      }
      // upload the new image if there's any
      const imageURL = await handleUploadAndSaveLogo(newSponsor);
      if (imageURL && newSponsor) {
        newSponsor.sponsor.logo = imageURL;
      }

      // Update the document
      newSponsor = await storeSponsor(currentUser?.appUser, newSponsor, true);

      if (newSponsor) {
        const newSponsors = { ...sponsors };
        newSponsors[newSponsor.id] = { ...newSponsor.sponsor };
        setSponsors(newSponsors);
        setSelectedSponsor(newSponsor);
        setImageSrc(null);
      }
    } else {
      console.error(`No sponsor is selected for saving!`);
    }
  };

  const handleDelete = async (sponsorId: string) => {
    if (!sponsorId) return;

    if (window.confirm("Are you sure you want to delete this sponsor?")) {
      const result = await deleteSponsor(currentUser?.appUser, sponsorId);

      switch (result) {
        case "success":
          const newSponsors = { ...sponsors };
          delete newSponsors[sponsorId];
          if (selectedSponsor && selectedSponsor.id === sponsorId) {
            setSelectedSponsor(null);
          }
          setSponsors(newSponsors);
          break;
        case "no-sponsor":
          window.alert("The sponsor does not exist");
          break;
        case "sponsor-in-use":
          window.alert("The sponsor is linked to other data and cannot be deleted");
          break;
        case "insufficient-permissions":
          window.alert("You do not have enough permissions to delete this sponsor.");
          break;

        default:
          console.error("Unknown error");
      }
    }
  };

  const handleSelectSponsor = (sponsorId: string) => {
    if (sponsorId) {
      const sponsor = sponsors[sponsorId];
      if (sponsor) {
        if (selectedSponsor && selectedSponsor?.id !== sponsorId) {
          const sWO = selectedSponsor.id ? sponsors[selectedSponsor.id] : null;

          if (!isEqual(sWO, selectedSponsor.sponsor)) {
            const conf = window.confirm(
              "The selected sponsor has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }

        if (imageSrc) {
          setImageSrc(null);
        }

        setSelectedSponsor({ id: sponsorId, sponsor: { ...sponsor } });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    if (event.target.name === "expiry_date") {
    if (selectedSponsor) {
      const newSponsor: SponsorDoc = {
        ...selectedSponsor,
        sponsor: {
          ...selectedSponsor.sponsor,
          [event.target.name]: event.target.value,
        },
      };
      setSelectedSponsor(newSponsor);
    }
  };

  const validate = () => {
    const newErrors = {
      name: "",
      email: "",
      address: EMPTY_ADDRESS_ERRORS,
      licenseNumber: "",
      color: "",
    };
    let isValid = true;
    if (selectedSponsor && !isNameValid(selectedSponsor.sponsor.name)) {
      newErrors.name = "Please, enter a valid name for this sponsor!";
      isValid = false;
    }

    if (selectedSponsor && !isEMailValid(selectedSponsor.sponsor.applicationsEmail)) {
      newErrors.email = "Please, enter a valid email addres, for this sponsor!";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("address input change");
    console.log("target: " + event.target.name);
    console.log("new value: " + event.target.value);

    if (selectedSponsor) {
      const newSponsor: SponsorDoc = { ...selectedSponsor };
      if (!newSponsor.sponsor.address) {
        newSponsor.sponsor.address = { ...EMPTY_ADDRESS };
      }
      newSponsor.sponsor.address = {
        ...newSponsor.sponsor.address,
        [event.target.name]: event.target.value,
      };
      console.log(newSponsor);
      setSelectedSponsor(newSponsor);
    }
  };

  const handleAddressCountryChange = (event: any, newValue: Country | null) => {
    if (selectedSponsor) {
      const newSponsor: SponsorDoc = { ...selectedSponsor };
      if (!newSponsor.sponsor.address) {
        newSponsor.sponsor.address = { ...EMPTY_ADDRESS };
      }
      newSponsor.sponsor.address.country = newValue ?? { ...EMPTY_COUNTRY };
      setSelectedSponsor(newSponsor);
    }
  };

  const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
    if (matchIsValidColor(newValue) && selectedSponsor) {
      const newSponsor: SponsorDoc = {
        ...selectedSponsor,
        sponsor: {
          ...selectedSponsor.sponsor,
          color: newValue,
        },
      };
      setSelectedSponsor(newSponsor);
    } // boolean
  };

  /*--------- Image Upload ----------- */
  const handleImageClick = () => {
    console.log("open image select");
    fileInputRef.current?.click();
    //setIsDialogOpen(true);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        if (e.target && e.target.result) {
          setImageSrc(e.target.result as string);
          setImageFileExtension(file.name.split(".").pop() || "");
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUploadAndSaveLogo = async (sponsor: SponsorDoc | null) => {
    if (imageSrc && sponsor) {
      // Step 1: Check if the sponsor already has an image URL and delete it
      if (sponsor.sponsor.logo) {
        //remove the timestamp if existing and then take only the image file name
        const filename = sponsor.sponsor.logo.split("?")[0].split("/").pop()?.split("%2F").pop();
        console.log("sponsor logo: ", sponsor.sponsor.logo);
        console.log("filename: ", filename);
        const oldImageRef = ref(storage, "sponsors/" + filename);
        getMetadata(oldImageRef)
          .then((metadata) => {
            // Log the metadata to see if cacheControl is set
            console.log("Metadata:", metadata);
            console.log(metadata.cacheControl);
          })
          .catch((error) => {
            console.error("Error fetching metadata:", error);
          });

        try {
          await deleteObject(oldImageRef);
        } catch (error) {
          console.error("Failed to delete the old image:", error);
          return; // If the delete fails, we might not want to proceed with uploading the new image
        }
      }
      //firebasestorage.googleapis.com/v0/b/visa-autofiller.appspot.com/o/sponsors%2FxiICNGd4RzGzpVS2U1Ux.png?alt=media&token=b830ee42-24c5-42d8-b4f9-c27c8eeb49fd?1719242465260
      // Step 2: Upload the image to Firebase Storage
      const storageRef = ref(storage, "sponsors/" + sponsor.id + "." + imageFileExtension || "jpg");
      // Define metadata with Cache-Control
      const metadata = {
        cacheControl: "public, max-age=31536000", // Example: 1 year
      };

      await uploadString(storageRef, imageSrc, "data_url", metadata);

      // Step 3: Get download URL
      const downloadURL: string = await getDownloadURL(storageRef);

      return urlAppendCacheBuster(downloadURL);
    } else {
      // console.error("Either the image source is not available or no sponsor is selected.");
      return "";
    }
  };

  return (
    <TitledPage title="Sponsors">
      <Box>
        {!currentUser?.appUser?.sponsorId && (
          <Box display={"flex"} sx={{ gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleAddSponsor}>
              Add New sponsor
            </Button>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
          }}
        >
          {!currentUser?.appUser?.sponsorId && (
            <Box sx={{ width: "50%", marginRight: "2%" }}>
              <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Name</TableCell>
                      <TableCell component="th">EMail</TableCell>
                      <TableCell component="th" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                      return (
                        <TableRow
                          key={sponsorId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() => handleSelectSponsor(sponsorId)}
                          className={
                            selectedSponsor && selectedSponsor.id === sponsorId ? "selected" : ""
                          }
                        >
                          <TableCell scope="row" size="small">
                            {sponsor.name}
                          </TableCell>
                          <TableCell scope="row" size="small">
                            {sponsor.applicationsEmail}
                          </TableCell>
                          <TableCell align="right" style={{ width: 100 }} size="small">
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={() => handleDelete(sponsorId || "")}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {/* Sponsor Details */}
          <Box sx={{ width: "48%", margin: !currentUser?.appUser?.sponsorId ? "initial" : "auto" }}>
            {selectedSponsor && (
              <Card>
                <CardHeader title={"Sponsor Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedSponsor && selectedSponsor.sponsor.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        margin="dense"
                        name="applicationsEmail"
                        label="Email for applications"
                        type="text"
                        fullWidth
                        value={selectedSponsor && selectedSponsor.sponsor.applicationsEmail}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!!errors.licenseNumber}
                        helperText={errors.licenseNumber}
                        margin="dense"
                        name="licenseNumber"
                        label="Sponsor's license number"
                        type="text"
                        fullWidth
                        value={(selectedSponsor && selectedSponsor.sponsor.licenseNumber) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <AddressInput
                        address={
                          selectedSponsor && selectedSponsor.sponsor.address
                            ? selectedSponsor.sponsor.address
                            : { ...EMPTY_ADDRESS }
                        }
                        errors={errors.address}
                        onInputChange={handleAddressInputChange}
                        onCountryChange={handleAddressCountryChange}
                        secondStreetLine={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MuiColorInput
                        error={!!errors.color}
                        helperText={errors.color}
                        margin="dense"
                        name="color"
                        label="Sponsor's main color"
                        fullWidth
                        value={(selectedSponsor && selectedSponsor.sponsor.color) || "#00ff00"}
                        onChange={handleColorChange}
                        format="hex"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        position="relative"
                        display="inline-block"
                        sx={{
                          backgroundImage: `url(${checked_background})`,
                          backgroundRepeat: "repeat",
                        }}
                      >
                        <img
                          src={imageSrc || selectedSponsor?.sponsor.logo || header_Image}
                          onClick={handleImageClick}
                          alt="Sponsor"
                          style={{ maxWidth: "600px", cursor: "pointer" }}
                        />
                        <Box
                          position="absolute"
                          bottom={0}
                          width="100%"
                          height="50px"
                          bgcolor="rgba(0, 0, 0, 0.5)"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap="10px"
                          sx={{ cursor: "pointer" }}
                          onClick={handleImageClick}
                        >
                          <EditIcon sx={{ color: "white" }} />
                          <Typography variant="body2" color="white">
                            Click to change the logo
                          </Typography>
                        </Box>
                      </Box>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveSponsor} sx={{ mr: 2 }}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default SponsorsData;
