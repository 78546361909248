import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";

import { parse } from "papaparse";
import { Applicant, ApplicantDoc, BOOL_APPLICANT_PROPERTIES } from "../types/applicants";
import {
  APPLICATION_MAPPING,
  CSV_APPLICANT_MAPPING,
  CSV_MAPPING_COUNTRY_PROPERTIES,
  CSV_MAPPING_DATE_PROPERTIES,
  parseCSVDate,
} from "../utils/importers/importerHOPS";
import { TitledPage } from "../components/titled-page";
import {
  DEFAULT_WORK_ORDER_DURATION,
  EmployersDict,
  SeasonsDict,
  WorkOrder,
  WorkOrderDoc,
  WorkOrdersDict,
} from "../types/orders";
import { AuthContext } from "../components/auth-provider";
import { AgentsDict } from "../types/agents";
import { loadAgents } from "../data-functions/agent-api";
import { loadSeasons, loadWorkOrders, storeWorkOrder } from "../data-functions/orders-api";
import { loadEmployers, loadSponsors } from "../data-functions/system-data_api";
import { SponsorsDict } from "../types/sponsors";
import { findCountry, formatDateToddMMYYYY, setNestedProperty } from "../utils/utils";
import { APPLICATION_STATUS, VisaApplication } from "../types/visa-application";
import { existingApplicants, storeApplicant } from "../data-functions/applicants-api";
import { storeApplication } from "../data-functions/applications-api";

type ImportOptions = {
  addApplication: boolean;
  status: string;
  fileHasOrders: boolean;
  addOrder: boolean;
};

const ImportDataPage: React.FC = () => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [selectedSponsor, setSelectedSponsor] = useState<string>("");
  const [agents, setAgents] = useState<AgentsDict>({});
  const [employers, setEmployers] = useState<EmployersDict>({});
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [file, setFile] = useState<File | null>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [importOptions, setImportOptions] = useState<ImportOptions>({
    addApplication: true,
    status: "new",
    fileHasOrders: true,
    addOrder: true,
  });

  const [errors, setErrors] = useState<{
    sponsor: string;
    agent: string;
  }>({ sponsor: "", agent: "" });

  const { currentUser } = useContext(AuthContext)!;

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);

    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  const fetchEmployers = async () => {
    const employers = await loadEmployers(currentUser?.appUser);
    if (employers) {
      setEmployers(employers);
    } else {
      setEmployers({});
    }
  };

  const fetchSponsors = async () => {
    const sponsors = await loadSponsors(currentUser?.appUser);
    if (sponsors) {
      setSponsors(sponsors);
    } else {
      setSponsors({});
    }
  };

  const fetchSeasons = async () => {
    const seasonsDict: SeasonsDict = await loadSeasons(currentUser?.appUser);
    setSeasons(seasonsDict);
  };

  useEffect(() => {
    if (currentUser?.appUser?.sponsorId) {
      setSelectedSponsor(currentUser.appUser.sponsorId);
    }
    fetchSponsors();
    fetchAgents();
    fetchEmployers();
    fetchSeasons();
  }, [currentUser]);

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const mapCsvRowToApplicant = async (row: Record<string, string>) => {
    const applicant: Partial<Applicant> = {
      name: "",
      surname: "",
      agentId: selectedAgent,
      sponsorId: currentUser?.appUser?.sponsorId || selectedSponsor,
    };

    const csvKeys = Object.keys(row);
    csvKeys.forEach((key) => {
      key = key.trim();
      const propertyName = CSV_APPLICANT_MAPPING[key];
      if (propertyName) {
        const csvValue = row[key] ? row[key].trim() : "";
        // check for a boolean value
        if (BOOL_APPLICANT_PROPERTIES.includes(propertyName)) {
          (applicant[propertyName as keyof Applicant] as any) = csvValue
            .toLowerCase()
            .includes("yes");
        } else if (propertyName.toLowerCase() === "gender") {
          applicant.gender = csvValue.slice(0, 1).toUpperCase();
        } else {
          setNestedProperty(applicant, propertyName, csvValue);
        }
      }

      const datePropertyName = CSV_MAPPING_DATE_PROPERTIES[key];
      if (datePropertyName) {
        const date = parseCSVDate(row[key]);
        setNestedProperty(applicant, datePropertyName, formatDate(date));
      }

      const countryPropertyName = CSV_MAPPING_COUNTRY_PROPERTIES[key];
      if (countryPropertyName) {
        const country = findCountry(row[key].trim());
        if (country) {
          setNestedProperty(applicant, countryPropertyName, country);
        }
      }
    });

    console.log("applicant: ", applicant);

    let appl: ApplicantDoc | null = { id: "", applicant: applicant as Applicant };
    if (!applicant.email) {
      return appl;
    } else {
      applicant.email = applicant.email.toLowerCase();
    }

    let newApplicantId: string | null = "";
    const existingApplicant = await existingApplicants(currentUser?.appUser, [applicant.email]);
    if (existingApplicant && existingApplicant.length > 0) {
      newApplicantId = existingApplicant[0].id;
      console.log("existing applicant: ", newApplicantId);
    } else {
      newApplicantId = await storeApplicant(currentUser?.appUser, appl, true);
    }

    if (newApplicantId) {
      appl.id = newApplicantId;
    }
    return appl;
  };

  const findSeasonId = (year: number): string | undefined => {
    return Object.entries(seasons).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
  };

  const parseWorkOrder = (row: Record<string, string>, workOrders: WorkOrdersDict) => {
    const oderKey = APPLICATION_MAPPING["order"];
    const orderNo = row[oderKey].trim();
    let workOrderId: string | undefined;
    if (orderNo) {
      workOrderId = Object.keys(workOrders).find((key) => {
        // console.log("wo: ", workOrders[key].name.toLowerCase());

        return workOrders[key].name.toLowerCase().indexOf("_order " + orderNo + "_") > -1;
      });
    }
    return workOrderId;
  };

  const createWorkOrder = async (
    row: Record<string, string>,
    workOrders: WorkOrdersDict,
    employerId: string
  ) => {
    const oderKey = APPLICATION_MAPPING["order"];
    const woBeginKey = APPLICATION_MAPPING["order_start_date"];

    const orderNo = row[oderKey].trim();

    const orderBeginDateString = row[woBeginKey].trim();

    const orderBeginDate = parseCSVDate(orderBeginDateString);

    const seasonId = findSeasonId(orderBeginDate.getFullYear());
    console.log("found season: ", seasonId ? seasons[seasonId] : "none");

    const woName =
      employers[employerId].name + "_Order " + orderNo + "_" + formatDateToddMMYYYY(orderBeginDate);

    const workOrder: WorkOrder = {
      name: woName,
      start_date: orderBeginDate.toISOString().slice(0, 10),
      employerId: employerId,
      seasonId: seasonId || "",
      sponsorId: selectedSponsor,
      agentId: selectedAgent,
      status: "open",
      demand: 0,
    };

    console.log("new work order: ", workOrder);

    orderBeginDate.setDate(orderBeginDate.getDate() + DEFAULT_WORK_ORDER_DURATION);
    workOrder.end_date = orderBeginDate.toISOString().slice(0, 10);
    let newWO = await storeWorkOrder(currentUser?.appUser, { id: "", workOrder }, true);
    if (!newWO) {
      newWO = { id: "", workOrder };
    }
    return newWO;
  };

  const parseEmployer = (row: Record<string, string>) => {
    const employerKey = APPLICATION_MAPPING["employer"];
    const employerName = row[employerKey].trim();
    let employerId: string | undefined;
    if (employerName) {
      employerId = Object.keys(employers).find(
        (key) => employers[key].name.toLowerCase() === employerName.toLowerCase()
      );
    }

    console.log(`parsed employer id ${employerId} for "${employerName}"`);
    return employerId;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      setShowProgress(true);
      try {
        const text: string = await file.text();
        const results = parse(text, {
          header: true,
          delimiter: ";",
          skipEmptyLines: "greedy",
        });
        // const applicantsRef = collection(db, "applicants");

        let workOrders: WorkOrdersDict = {};
        if (importOptions.addApplication) {
          workOrders = await loadWorkOrders(currentUser?.appUser, true);
        }

        let imported = 0;
        let missingEmployers = [];
        for (let row of results.data) {
          console.log("parsing row: ", row);
          if (typeof row === "object" && row !== null) {
            const dataRow = row as Record<string, string>;
            const applicant = await mapCsvRowToApplicant(dataRow);
            if (applicant && applicant.id) {
              let employerId = "";
              let workOrderId = "";
              let wo: WorkOrderDoc | null = null;
              let seasonId = "";

              if (importOptions.addApplication) {
                workOrderId = parseWorkOrder(dataRow, workOrders) || "";
                console.log("found work order (id): ", workOrderId);

                if (!workOrderId) {
                  if (importOptions.addOrder) {
                    console.log("creating work order");
                    employerId = parseEmployer(dataRow) || "";

                    console.log("found employer (id): ", employerId);

                    if (employerId) {
                      wo = await createWorkOrder(dataRow, workOrders, employerId || "");
                      console.log("new work order id: ", wo.id);
                      if (wo.id && !workOrders[wo.id]) {
                        workOrders[wo.id] = wo.workOrder;
                        workOrderId = wo.id;
                      }
                      seasonId = wo.workOrder.seasonId;
                    } else {
                      missingEmployers.push(dataRow[APPLICATION_MAPPING["employer"]]);
                      continue;
                    }
                  } else {
                    console.log("No work order found for applicant");
                  }
                } else {
                  seasonId = workOrders[workOrderId].seasonId;
                  console.log("found season: ", seasonId ? seasons[seasonId] : "none");
                }
                const application: VisaApplication = {
                  applicantId: applicant.id,
                  type: { key: "seasonal", label: "Seasonal Worker" },
                  status: importOptions.status || "new",
                  seasonId,
                  workOrderId: workOrderId || "",
                  sponsorId: selectedSponsor,
                  agentId: selectedAgent,
                };
                console.log("application: ", application);
                const newAppl = await storeApplication(
                  currentUser?.appUser,
                  { id: "", application },
                  true
                );
                if (newAppl) {
                  imported++;
                  console.log("application stored successfully");
                } else {
                  console.log("application could not be stored");
                }
              } else {
                imported++;
              }
            }
          }
        }
        window.alert(`Imported ${imported} applicants`);
      } finally {
        setShowProgress(false);
      }
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImportOptions({ ...importOptions, [e.target.name]: e.target.checked });
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "agentId":
        setSelectedAgent(event.target.value);
        break;
      case "sponsorId":
        setSelectedSponsor(event.target.value);
        break;
      case "status":
        setImportOptions({ ...importOptions, status: event.target.value });
        break;
    }
  };

  return (
    <TitledPage title="Import Applicants">
      <Box maxWidth="md" margin="auto" sx={{}}>
        <Card sx={{ padding: "2rem", border: "solid 1px #DFDFDF" }} variant="outlined">
          <CardContent sx={{ padding: 0 }}>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <TextField
                  type="file"
                  id="outlined-basic"
                  label="Import file"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileChange}
                />
              </Grid>
              {currentUser && currentUser.appUser && !currentUser.appUser.sponsorId && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Assign a sponsor</Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"sponsorId"}
                      label={"Sponsor"}
                      select
                      error={!!errors.sponsor}
                      helperText={errors.sponsor}
                      onChange={onInputComboChange}
                      value={selectedSponsor || ""}
                      required
                      fullWidth
                      margin={"dense"}
                    >
                      <MenuItem value="" key="no_sponsor">
                        <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                      </MenuItem>
                      {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                        return (
                          <MenuItem value={sponsorId} key={sponsorId}>
                            {sponsor.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </React.Fragment>
              )}

              {currentUser && currentUser.appUser && !currentUser.appUser.agentId && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Assign an agent</Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"agentId"}
                      label={"Agent"}
                      select
                      error={!!errors.agent}
                      helperText={errors.agent}
                      onChange={onInputComboChange}
                      value={selectedAgent || ""}
                      required
                      fullWidth
                      margin={"dense"}
                    >
                      <MenuItem value="" key="no_agent">
                        <Typography fontStyle={"italic"}>no agent selected</Typography>
                      </MenuItem>
                      {Object.entries(agents).map(([agentId, agent]) => {
                        return (
                          <MenuItem value={agentId} key={agentId}>
                            {agent.company}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={importOptions.addApplication}
                      onChange={handleOptionChange}
                      name="addApplication"
                      disabled={showProgress || !file}
                    />
                  }
                  label="Automatically create an application for each applicant"
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ paddingLeft: 3 }}>
                <TextField
                  name={"status"}
                  label={"Application status"}
                  select
                  onChange={onInputComboChange}
                  value={importOptions.status || ""}
                  required
                  fullWidth
                  disabled={!importOptions.addApplication}
                  margin={"dense"}
                >
                  {Object.entries(APPLICATION_STATUS).map(([key, vtype]) => {
                    return (
                      <MenuItem value={vtype.key} key={key}>
                        {vtype.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ paddingLeft: 3 }}
                  control={
                    <Checkbox
                      checked={importOptions.addOrder}
                      onChange={handleOptionChange}
                      name="addOrder"
                      disabled={showProgress || !file || !importOptions.addApplication}
                    />
                  }
                  label="Automatically create work orders if not present"
                />
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: 2 }}>
                <Button variant="contained" onClick={handleUpload}>
                  Import the applicants
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </TitledPage>
  );
};

export default ImportDataPage;
