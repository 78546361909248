import React, { useState, useEffect, useMemo, useContext } from "react";

import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Tooltip, IconButton } from "@mui/material";
import { Box, Card, CardHeader, CardContent } from "@mui/material";
import {
  Delete as DeleteIcon,
  AssignmentInd,
  BadgeOutlined,
  Save as SaveIcon,
  ToggleOff,
  ToggleOn,
  Input as InputIcon,
  InfoOutlined as InfoIcon,
  ErrorOutline,
  Restore,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import AddCandidateDialog from "../dialogs/dialogCandidateSettings";

import {
  Candidate,
  CandidateDoc,
  CandidatesDict,
  CANDIDATE_PROPERTY_MAPPING,
  CampaignsDict,
  CandidateVerificaion,
} from "../../types/recruiting";
import { TitledPage } from "../../components/titled-page";
import { getCandidateStatusChip, getCandidateStatusText } from "../../utils/candidate-utils";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import ConvertCandidatesDialog from "../dialogs/dialogConvertCandidates";
import { AuthContext } from "../../components/auth-provider";
import {
  deleteCandidate,
  loadCampaigns,
  loadCandidate,
  loadCandidates,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { generateCandidatesCSV } from "../../utils/exporters/exporters";
import { TableVirtuoso } from "react-virtuoso";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { useNavigate } from "react-router-dom";
import { LogEntry } from "../../types/commons";
import { logEvent } from "../../utils/db-utils";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";
import { isDateInThePast } from "../../utils/utils";
import ResetVerificationDialog from "../dialogs/dialogResetVerification";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "surname",
    numeric: false,
    disablePadding: false,
    label: "Family Name",
    sortable: true,
  },
  {
    id: "has_passport",
    numeric: false,
    disablePadding: false,
    label: "Pass",
    sortable: true,
    tooltip: "Has passport?",
    width: "30px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true,
    width: "110px",
  },
  {
    id: "verified",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: true,
    width: "30px",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
    width: "110px",
  },
];

const CandidatesPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDoc | null>(null);
  const [editCandidate, setEditdCandidate] = useState<CandidateDoc | null>(null);
  const [csvMappingEntries, setCsvMappingEntries] = useState<Record<string, string>>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [selectedCampaign, setCampaign] = useState<string>("");
  const [showOpenCampaigns, setShowOpenCampaigns] = useState<boolean>(true);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [convertCandidates, setConvertCandidates] = useState<CandidatesDict>({});
  const [openConvertDialog, setConverDialogOpen] = useState(false);

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const [openResetVerificationDialog, setOpenResetVerificationDialog] = useState(false);
  const [resetVerificationCandidates, setResetVerificationCandidates] = useState<CandidatesDict>(
    {}
  );

  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const fetchCandidates = async (campaingId: string) => {
    let candidatesDict: CandidatesDict;

    if (campaingId === "all_campaigns") {
      candidatesDict = await loadCandidates(currentUser?.appUser, campaigns);
    } else if (campaingId === "no_campaign") {
      candidatesDict = await loadCandidates(currentUser?.appUser, {});
    } else {
      const campaign: CampaignsDict = {};
      campaign[campaingId] = campaigns[campaingId];
      candidatesDict = await loadCandidates(currentUser?.appUser, campaign);
    }

    setCandidates(candidatesDict);
  };

  const fetchCampaigns = async () => {
    const campaignsDict: CampaignsDict = await loadCampaigns(currentUser?.appUser);

    setCampaigns(campaignsDict);

    const fCampaigns = sortAndFilterCampaigns(campaignsDict, true);
    if (fCampaigns.length > 0) {
      setCampaign(fCampaigns[0][0]);
    } else setCampaign("all_campaigns");
  };

  useEffect(() => {
    setCsvMappingEntries(CANDIDATE_PROPERTY_MAPPING);
    fetchCampaigns();
    setShowOpenCampaigns(true);
    //    fetchCandidates();
  }, []);

  useEffect(() => {
    setSelectedCandidate(null);
    fetchCampaigns();
  }, [currentUser]);

  useEffect(() => {
    if (selectedCampaign) {
      setSearchValue("");
      fetchCandidates(selectedCampaign);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (editCandidate) {
      handleClickOpen(true);
    }
  }, [editCandidate]);

  const handleClickOpen = (edit: boolean = false) => {
    setEditMode(edit && !!editCandidate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditdCandidate(null);
    // fetchCandidates(selectedCampaign);
  };

  const handleSaveCandidate = async (candidate: CandidateDoc) => {
    const appDoc = await saveCandidate(currentUser?.appUser, { ...candidate }, true);
    if (appDoc) {
      const newCandidates = { ...candidates };
      newCandidates[appDoc.id] = appDoc.candidate;

      setCandidates(newCandidates);
      if (selectedCandidate?.id === appDoc.id) {
        setSelectedCandidate(appDoc);
      }
    } else {
      console.log("error saving candidate");
    }
  };

  //const handleExport = async () => {
  // const q = query(collection(db, "candidates"), where("application.status", "==", "new"));
  // const querySnapshot = await getDocs(q);
  // const candidates = querySnapshot.docs
  //   .map((doc) => doc.data() as Candidate)
  //   .filter((candidate) => candidate.application && candidate.application.dataFormCompleted);
  // // Convert candidates data to CSV format:
  // const csvData = addHOPSCosCandidates([], candidates);
  // const curentDate = new Date().toISOString().split("T")[0];
  // // Create a blob from the CSV data and download:
  // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.style.display = "none";
  // a.href = url;
  // a.download = "candidates " + curentDate + ".csv"; // specify the file name
  // document.body.appendChild(a);
  // a.click();
  // document.body.removeChild(a);
  // window.URL.revokeObjectURL(url);
  //};

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map(([id, candidate]) => id);
      setSelected(newSelected);
    } else setSelected([]);
  };

  const handleCheckRow = (event: React.ChangeEvent<HTMLInputElement>, candidateId: string) => {
    // setChecked(event.target.checked);
    const selectedIndex = selected.indexOf(candidateId);
    const newSelected: string[] = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(candidateId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.includes(id);

  const handleDelete = async (candidateId: string) => {
    if (!candidateId) return;

    if (window.confirm("Are you sure you want to delete this candidate?")) {
      deleteCandidate(candidateId).then(() => {
        const newCandidates = { ...candidates };
        delete newCandidates[candidateId];
        if (selectedCandidate && selectedCandidate.id === candidateId) {
          setSelectedCandidate(null);
        }

        if (selected.indexOf(candidateId) >= 0) {
          const newSelected = [...selected];
          newSelected.splice(selected.indexOf(candidateId), 1);
          setSelected(newSelected);
        }
        setCandidates(newCandidates);
      });
    }
  };

  const handleSelectCandidate = async (candidateId: string) => {
    console.log("select candidate: ", candidateId);

    let loadedCandidate = await loadCandidate(candidateId);

    if (loadedCandidate) {
      setSelectedCandidate(loadedCandidate);
      console.log("selected candidate: ", loadedCandidate);
    }
  };

  const handleEditCandidate = async (candidate: CandidateDoc) => {
    if (candidate) {
      setEditdCandidate(candidate);
    }
  };

  const handleSelectCampaign = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCampaign(value);
    setSelectedCandidate(null);
    if (value !== "all_campaigns") setSelected([]);
  };

  const getCampaignName = (candidate: Candidate) => {
    if (candidate && candidate.campaignId && campaigns[candidate.campaignId]) {
      return campaigns[candidate.campaignId].name;
    } else {
      return "n/a";
    }
  };

  const handleToggleShowopenCampaigns = () => {
    console.log("toggle show opened campaigns");
    const newSO = !showOpenCampaigns;
    if (newSO && selectedCampaign && campaigns[selectedCampaign]) {
      if (campaigns[selectedCampaign].status === "closed") {
        const fCampaigns = sortAndFilterCampaigns(campaigns, newSO);
        if (fCampaigns.length > 0) {
          setCampaign(fCampaigns[0][0]);
        } else setCampaign("all_campaigns");
        setSelectedCandidate(null);
        setSelected([]);
      }
    }
    setShowOpenCampaigns(newSO);
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  const handleResetVerifications = async () => {
    const newCandidates: CandidatesDict = {};
    for (const candidateId of selected) {
      const candidate = candidates[candidateId];
      if (candidate.verification && !candidate.verification.verifiedOn) {
        newCandidates[candidateId] = { ...candidate };
      }
    }
    setResetVerificationCandidates(newCandidates);
    setOpenResetVerificationDialog(true);
  };

  const getAnswerValue = (candidate: Candidate, key: string) => {
    if (candidate.questions) {
      const value = candidate.questions[key];
      switch (value) {
        case "true":
          return "Yes";
        case "false":
          return "No";

        default:
          return value;
      }
    }
  };

  const getCampaignChip = (campaignId: string) => {
    const campaign = campaigns[campaignId];

    switch (campaign.status) {
      case "open":
        return <Chip label="Open" color="success" size="small" />;

      case "closed":
        return <Chip label="Closed" color="error" size="small" />;

      default:
        return <Chip label="New" color="primary" variant="outlined" size="small" />;
    }
  };

  const displayCandidateDetails = (selectedCandidate: CandidateDoc | null) => {
    if (!selectedCandidate) {
      return;
    }

    let verificationExired = false;
    if (
      selectedCandidate.candidate.verification &&
      selectedCandidate.candidate.verification.expiryDate &&
      !selectedCandidate.candidate.verification.verifiedOn
    ) {
      verificationExired = isDateInThePast(selectedCandidate.candidate.verification.expiryDate);
    }

    return (
      <Box sx={{ paddingBottom: "16px" }}>
        <Card>
          <CardHeader
            title={selectedCandidate.candidate.name + " " + selectedCandidate.candidate.surname}
          />
          <CardContent>
            <Grid container spacing={1} padding={1}>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Name:
                  </Typography>{" "}
                  {selectedCandidate.candidate.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Family Name:
                  </Typography>{" "}
                  {selectedCandidate.candidate.surname}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    EMail:
                  </Typography>{" "}
                  {selectedCandidate.candidate.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Phone:
                  </Typography>{" "}
                  {selectedCandidate.candidate.phone}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Has passport:
                  </Typography>{" "}
                  {selectedCandidate.candidate.has_passport ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Passport ID:
                  </Typography>{" "}
                  {selectedCandidate.candidate.passportId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Campaign:
                  </Typography>{" "}
                  {getCampaignName(selectedCandidate.candidate)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                  Status:{" "}
                </Typography>{" "}
                {getCandidateStatusChip(selectedCandidate.candidate.status)}
              </Grid>
              {verificationExired && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Verification expired on:{" "}
                      </Typography>
                      <Typography component="span" color="red">
                        {selectedCandidate.candidate.verification?.expiryDate}
                      </Typography>
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}

              {!verificationExired && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Verification code:{" "}
                      </Typography>
                      {selectedCandidate.candidate.verification?.confirmationCode || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Interviewed by:{" "}
                      </Typography>
                      {selectedCandidate.candidate.interviewer || "n/a"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Interviewer comments:{" "}
                      </Typography>{" "}
                      {selectedCandidate.candidate.note || "-"}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            {selectedCandidate.candidate.questions && (
              <Table
                sx={{ width: "100%", marginTop: "8px" }}
                size="small"
                aria-label="questions table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Question</TableCell>
                    <TableCell component="th" align="left">
                      Answer
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(selectedCandidate.candidate.questions)
                    .sort()
                    .map((key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>{csvMappingEntries[key] || key}</TableCell>
                          <TableCell align="left">
                            {getAnswerValue(selectedCandidate.candidate, key)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  /* ------------ Search functions -------------- */
  const filteredCandidates = useMemo(
    () =>
      Object.entries(candidates).filter(([candidateId, candidate]) => {
        try {
          return (
            (searchValue.length < 3 ||
              candidate.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              candidate.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
              candidate.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
              getCandidateStatusText(candidate.status)
                .toLowerCase()
                .includes(searchValue.toLowerCase())) &&
            (["all_campaigns", "no_campaign"].includes(selectedCampaign) ||
              (candidate.campaignId && candidate.campaignId === selectedCampaign))
          );
        } catch (err) {
          console.error("filter exception:");
          console.log(candidate);
          return false;
        }
      }),
    [searchValue, candidates, selectedCampaign]
  );

  const visibleRows = useMemo(
    () => filteredCandidates.slice().sort(getComparator(order, orderBy as keyof Candidate)),
    [order, orderBy, filteredCandidates]
  );

  const sortAndFilterCampaigns = (fCampaigns: CampaignsDict, showOpen: boolean) => {
    return Object.entries(fCampaigns)
      .filter(([campaignId, campaign]) => {
        return !showOpen || campaign.status !== "closed";
      })
      .sort(([idA, campaignA], [idB, campaignB]) => {
        if (campaignA.name < campaignB.name) {
          return -1;
        }
        if (campaignA.name > campaignB.name) {
          return 1;
        }
        return 0;
      });
  };

  const filteredCampaigns = useMemo(
    () => sortAndFilterCampaigns(campaigns, showOpenCampaigns),
    [showOpenCampaigns, campaigns]
  );

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleConvertCandidates = (event: React.MouseEvent<unknown>) => {
    const allApproved = selected.length === 0;

    const convCandidates: CandidatesDict = {};

    filteredCandidates.forEach(([candidateId, candidate]) => {
      if (
        candidate.status !== "applicant" &&
        ((allApproved && candidate.status === "approved") ||
          (!allApproved && isSelected(candidateId)))
      ) {
        convCandidates[candidateId] = { ...candidate };
      }
    });

    setConvertCandidates(convCandidates);
    setConverDialogOpen(true);
  };

  const handleCloseConvertDialog = () => {
    setConverDialogOpen(false);
    fetchCandidates(selectedCampaign);
  };

  const handleExport = async () => {
    let cmp: string;
    switch (selectedCampaign) {
      case "all_campaigns":
        cmp = "all campaigns";
        break;
      case "no_campaign":
        cmp = "candidates without a campaign";
        break;
      default:
        cmp = campaigns[selectedCampaign].name;
        break;
    }

    // Convert candidates data to CSV format:
    const candidates = visibleRows.map(([id, candidate]) => candidate);
    try {
      const csvData = generateCandidatesCSV(candidates);
      const curentDate = new Date().toISOString().split("T")[0];
      // Create a blob from the CSV data and download:
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = cmp + " - " + curentDate + ".csv"; // specify the file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } finally {
      const candidateIds = visibleRows.map(([id, candidate]) => id);
      const logEntry: LogEntry = {
        collection: "candidates",
        documentId: "",
        sponsorId: currentUser?.appUser?.sponsorId || "",
        operation: "export",
        changedFields: { candidates: candidateIds },
      };
      logEvent(logEntry);
    }
  };

  const getVerificationIcon = (verification: CandidateVerificaion | null) => {
    let verificationExired = false;
    if (verification && verification.expiryDate && !verification.verifiedOn) {
      verificationExired = isDateInThePast(verification.expiryDate);
    }

    if (verificationExired) {
      return (
        <Tooltip title="Verification expired">
          <IconButton size="small">
            <ErrorOutline color="error" />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  return (
    <TitledPage title="Candidates">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={() => handleClickOpen()}
          >
            Add Candidate
          </Button>

          <AddCandidateDialog
            open={open}
            onClose={handleClose}
            onSave={handleSaveCandidate}
            candidate={editMode ? selectedCandidate : null}
            campaign={
              selectedCampaign &&
              selectedCampaign !== "all_campaigns" &&
              selectedCampaign !== "no_campaign"
                ? selectedCampaign
                : ""
            }
          />

          <ResetVerificationDialog
            candidates={resetVerificationCandidates}
            open={openResetVerificationDialog}
            onClose={() => setOpenResetVerificationDialog(false)}
          />

          <Tooltip
            title={
              selected.length === 0
                ? "Convert all approved candidates to applicants"
                : "Convert selected candidates to applicants"
            }
          >
            <>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                disabled={visibleRows.length === 0}
                onClick={handleConvertCandidates}
              >
                <AssignmentInd />
              </IconButton>
            </>
          </Tooltip>

          <ConvertCandidatesDialog
            candidates={convertCandidates}
            campaigns={campaigns}
            open={openConvertDialog}
            onClose={handleCloseConvertDialog}
          />
          <Tooltip title="Export to CSV">
            <>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                disabled={visibleRows.length === 0}
                onClick={handleExport}
              >
                <SaveIcon />
              </IconButton>
            </>
          </Tooltip>

          <Tooltip title="Reset Verifications (expired only)">
            <span>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                disabled={!(selectedCandidate || selected.length > 0)}
                onClick={handleResetVerifications}
              >
                <Restore />
              </IconButton>
            </span>
          </Tooltip>

          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    disabled={!selectedCandidate}
                    onClick={() => handleShowHistory(selectedCandidate?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}

          <Divider orientation="vertical" flexItem sx={{ marginTop: "8px", marginBottom: "4px" }} />
          <TextField
            name={"campaign"}
            label={"Campaign"}
            size="small"
            select
            onChange={handleSelectCampaign}
            value={selectedCampaign || ""}
            required
            margin={"dense"}
            sx={{
              width: "600px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={showOpenCampaigns ? "Show all campaigns" : "Show open campaigns"}>
                    <IconButton onClick={handleToggleShowopenCampaigns}>
                      {showOpenCampaigns ? <ToggleOn sx={{ color: "green" }} /> : <ToggleOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="all_campaigns" key="all_campaigns">
              <Typography fontStyle={"italic"}>Show all campaigns</Typography>
            </MenuItem>
            {!currentUser?.appUser?.sponsorId && (
              <MenuItem value="no_campaign" key="no_campaign">
                <Typography fontStyle={"italic"}>Show candidates without a campaigns</Typography>
              </MenuItem>
            )}
            {Object.values(filteredCampaigns).map(([campaignId, campaign]) => {
              return (
                <MenuItem
                  value={campaignId}
                  key={campaignId}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {campaign.name}
                  {getCampaignChip(campaignId)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px", marginTop: "8px", marginBottom: "4px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            minHeight: "400px",
            height: "calc(100vh - 210px)",
            paddingBottom: "4px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "0",
              maxWidth: `calc(100% - 550px)`,
              marginRight: "8px",
            }}
          >
            <TableVirtuoso
              data={visibleRows}
              context={{
                selectedId: selectedCandidate?.id || "",
                handleSelect: handleSelectCandidate,
              }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  selectable={true}
                  headCells={headCells}
                />
              )}
              fixedFooterContent={() => (
                <TableRow>
                  {/* +1 for the checkbox */}
                  <TableCell align="right" colSpan={headCells.length + 1}>
                    {visibleRows.length + " "} Candidates
                  </TableCell>
                </TableRow>
              )}
              itemContent={(index, [candidateId, candidate]) => {
                const isItemSelected = isSelected(candidateId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onChange={(event) => handleCheckRow(event, candidateId)}
                      />
                    </TableCell>
                    <TableCell scope="row" size="small">
                      {candidate.name}
                    </TableCell>
                    <TableCell size="small">{candidate.surname}</TableCell>
                    <TableCell size="small">
                      {candidate.has_passport && <BadgeOutlined color="success" />}
                    </TableCell>
                    <TableCell size="small">{getCandidateStatusChip(candidate.status)}</TableCell>
                    <TableCell size="small">
                      {getVerificationIcon(candidate.verification)}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {candidate.applicantId && (
                        <Tooltip title="Go to Applicant">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              navigate(`/applicants/${candidate.applicantId}`);
                            }}
                          >
                            <InputIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Edit candidate">
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleEditCandidate({ id: candidateId, candidate });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>{" "}
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(candidateId || "");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>
          <Box
            id="details"
            sx={{ width: "48%", maxWidth: "550px", marginBottom: "4px", flexGrow: 1 }}
          >
            {displayCandidateDetails(selectedCandidate)}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default CandidatesPage;
