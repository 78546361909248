import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Applicant, ApplicantDoc, EMPTY_APPLICANT_DOC } from "../../types/applicants";
import { isEMailValid, isNameValid } from "../../utils/utils";
import { AuthContext } from "../../components/auth-provider";
import { AgentsDict } from "../../types/agents";
import { SponsorsDict } from "../../types/sponsors";
import { loadSponsors } from "../../data-functions/system-data_api";
import { loadAgents } from "../../data-functions/agent-api";
import { LanguageSwitcher } from "../../components/language-switcher";
import { SupportedLanguages } from "../../languages/languages";
import { existingApplicants } from "../../data-functions/applicants-api";

interface AddApplicantDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (applicant: ApplicantDoc) => void;
  applicant: ApplicantDoc | null;
}

const EMPTY_ERRORS = { name: "", surname: "", email: "", expiry_date: "" };

const AddApplicantDialog = ({
  open,
  onClose,
  onSave,
  applicant: initialApplicant,
}: AddApplicantDialogProps) => {
  const [applicantDoc, setApplicant] = useState<ApplicantDoc>({
    ...EMPTY_APPLICANT_DOC,
  });
  const [agents, setAgents] = useState<AgentsDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});

  const [errors, setErrors] = useState<{
    name: string;
    surname: string;
    email: string;
    expiry_date: string;
    sponsorId?: string;
    agentId?: string;
  }>({ ...EMPTY_ERRORS });

  const { currentUser } = useContext(AuthContext)!;

  const fetchAgents = async () => {
    const agentsDict: AgentsDict = await loadAgents(currentUser?.appUser);
    setAgents(agentsDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  useEffect(() => {
    console.log("initialApplicant", initialApplicant);
    if (initialApplicant) {
      setApplicant({ ...initialApplicant });
    } else {
      const newApplicant: ApplicantDoc = { ...EMPTY_APPLICANT_DOC };
      newApplicant.applicant.sponsorId = currentUser?.appUser?.sponsorId || "";
      newApplicant.applicant.agentId = currentUser?.appUser?.agentId;
      setApplicant(newApplicant);
    }
  }, [initialApplicant]);

  useEffect(() => {
    fetchSponsors();
    fetchAgents();
  }, [currentUser]);

  const validateInput = async () => {
    let newErrors = {
      name: "",
      surname: "",
      email: "",
      expiry_date: "",
      sponsorId: "",
      agentId: "",
    };
    let formIsValid = true;

    if (!applicantDoc.applicant.sponsorId) {
      newErrors.sponsorId = "Please, select a sponsor for this applicant";
      formIsValid = false;
    }

    if (!(applicantDoc.applicant.name && isNameValid(applicantDoc.applicant.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(applicantDoc.applicant.surname && isNameValid(applicantDoc.applicant.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(applicantDoc.applicant.email && isEMailValid(applicantDoc.applicant.email))) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    } else if (!applicantDoc.id) {
      const existingEmails = await existingApplicants(currentUser?.appUser, [
        applicantDoc.applicant.email,
      ]);

      if (existingEmails.length > 0) {
        newErrors.email = "Applicant with this email address already exists";
        formIsValid = false;
      }
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = async () => {
    const res = await validateInput();
    if (!res) {
      return;
    }

    onSave(applicantDoc);

    setApplicant({ ...EMPTY_APPLICANT_DOC });
    handleClose();
  };

  const handleClose = () => {
    setErrors({ ...EMPTY_ERRORS });
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApplicant: Applicant = {
      ...applicantDoc.applicant,
      [event.target.name]: event.target.value,
    };

    setApplicant({ ...applicantDoc, applicant: newApplicant });
  };

  const onInputSponsorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    if (applicantDoc && applicantDoc.applicant) {
      const newApplicant: ApplicantDoc = {
        ...applicantDoc,
      };
      if (key) {
        // Assuming newApplicant is of type any, key is of type string, and value is of type any
        (newApplicant as any).applicant[key as string] = value;
      }

      setApplicant(newApplicant);
    }
  };

  const handleLanguageChange = (newLanguage: string | null) => {
    if (newLanguage) {
      const newApplicant: ApplicantDoc = {
        ...applicantDoc,
      };
      newApplicant.applicant.preferredLanguage = newLanguage;
      setApplicant(newApplicant);
    }
  };

  const filteredAgents = useMemo(
    () =>
      Object.entries(agents).filter(([agentId, agent]) => {
        if (!(applicantDoc && applicantDoc.applicant.sponsorId)) {
          return false;
        }
        return agent.sponsorId === applicantDoc.applicant.sponsorId;
      }),
    [applicantDoc, agents]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Applicant</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the details of the new applicant.</DialogContentText>
        <Grid container spacing={2} padding={2}>
          {currentUser && currentUser.appUser && !currentUser.appUser.sponsorId && (
            <Grid item xs={12} md={8}>
              <TextField
                error={!!errors.sponsorId}
                helperText={errors.sponsorId}
                name={"sponsorId"}
                label={"Sponsor"}
                select
                onChange={onInputSponsorChange}
                value={
                  (applicantDoc && applicantDoc.applicant && applicantDoc.applicant.sponsorId) || ""
                }
                required
                margin={"dense"}
                fullWidth
              >
                <MenuItem value={""} key={"no_sponsor"}>
                  {"Please, select a sponsor..."}
                </MenuItem>
                {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                  return (
                    <MenuItem value={sponsorId} key={sponsorId}>
                      {sponsor.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}

          {applicantDoc && applicantDoc.applicant && applicantDoc.applicant.sponsorId && (
            <Grid item xs={12} md={12}>
              <TextField
                error={!!errors.agentId}
                helperText={errors.agentId}
                name={"agentId"}
                label={"Agent"}
                select
                onChange={onInputSponsorChange}
                value={
                  (applicantDoc && applicantDoc.applicant && applicantDoc.applicant.agentId) || ""
                }
                margin={"dense"}
                fullWidth
              >
                <MenuItem value={""} key={"no_agent"}>
                  {"Please, select an agent..."}
                </MenuItem>
                {filteredAgents.map(([agentId, agent]) => {
                  return (
                    <MenuItem value={agentId} key={agentId}>
                      {agent.company}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
          {applicantDoc && applicantDoc.applicant && !applicantDoc.applicant.sponsorId && (
            <Grid item xs={12} md={12}>
              <Typography variant="body1">
                Agent:{" "}
                {(applicantDoc &&
                  applicantDoc.applicant &&
                  applicantDoc.applicant.agentId &&
                  agents[applicantDoc.applicant.agentId]?.company) ||
                  "Select a sponsor first"}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.name}
              helperText={errors.name}
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={(applicantDoc && applicantDoc.applicant.name) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.surname}
              helperText={errors.surname}
              margin="dense"
              name="surname"
              label="Surname"
              type="text"
              fullWidth
              value={(applicantDoc && applicantDoc.applicant.surname) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errors.email}
              helperText={errors.email}
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              value={(applicantDoc && applicantDoc.applicant.email) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Preferred language:{" "}
              <LanguageSwitcher
                initialLanguage={
                  applicantDoc.applicant?.preferredLanguage
                    ? (applicantDoc.applicant?.preferredLanguage as SupportedLanguages)
                    : "en"
                }
                onLanguageChange={handleLanguageChange}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddApplicantDialog;
