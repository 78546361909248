import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { CandidatesDict, VERIFICATION_EXPIRY_HOURS } from "../../types/recruiting";
import { saveCandidate } from "../../data-functions/recruiting_api";
import { AuthContext } from "../../components/auth-provider";
import { sendEmailVerificationEmail } from "../../utils/candidate-utils";

interface ResetVerificationDialogProps {
  candidates: CandidatesDict;
  open: boolean;
  onClose: () => void;
}

const ResetVerificationDialog = ({
  candidates: initialCandidates,
  open,
  onClose,
}: ResetVerificationDialogProps) => {
  const [openSnackError, setOpenSnackError] = useState(false);
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [sendEmail, setSendEmail] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [progress, setProgress] = useState(0);

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const newCandidates = { ...initialCandidates };
    setActivePage(1);
    setSendEmail(true);
    setCandidates(newCandidates);
  }, [initialCandidates]);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackError(false);
  };

  //   const onCopySuccess = () => {
  //     setOpenSnack(true);
  //   };

  //   const onCopyError = (reason: any) => {
  //     console.error(reason);
  //     setOpenSnackError(true);
  //   };

  const handleSendEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(event.target.checked);
  };

  const ResetVerification = async () => {
    setProgress(0);
    setActivePage(2);

    const candidateIds = Object.keys(candidates);
    const numCandidates = candidateIds.length;
    let candidateIdToMail: string[] = [];

    for (let i = 0; i < numCandidates; i++) {
      const candidate = candidates[candidateIds[i]];
      if (
        candidate &&
        candidate.verification &&
        candidate.verification.expiryDate &&
        !candidate.verification.verifiedOn
      ) {
        candidate.status = "new";
        if (!candidate.verification.verificationCode) {
          candidate.verification.verificationCode = uuidv4();
        }
        candidate.verification.expiryDate = new Date(
          Date.now() + VERIFICATION_EXPIRY_HOURS * 60 * 60 * 1000
        ).toISOString();
        await saveCandidate(currentUser?.appUser, { id: candidateIds[i], candidate: candidate });
        console.log("candidate: ", candidate);
        if (sendEmail) {
          candidateIdToMail.push(candidateIds[i]);
        }
      }
      setProgress(Math.round((i / numCandidates) * 100));
      console.log("progress: " + progress);
    }

    if (sendEmail && candidateIdToMail.length > 0) {
      sendEmailVerificationEmail(candidateIdToMail, "candidate_verification").then(
        (response) => {
          setActivePage(3);
        },
        (error) => {
          setActivePage(3);
        }
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  const getActivePage = () => {
    switch (activePage) {
      case 1:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                {candidates && Object.keys(candidates).length > 0
                  ? Object.keys(candidates).length + " "
                  : "No "}
                candidates will have their verifications reset.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginTop: "16px" }}
                  control={
                    <Switch onChange={handleSendEmailChange} checked={sendEmail} name="sendEmail" />
                  }
                  label="Send the candidate a link confirm the registration"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={Object.keys(candidates).length === 0}
                sx={{ marginTop: "16px" }}
                onClick={ResetVerification}
              >
                Reset verifications
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        );
      case 2:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <CircularProgress />
              <Typography>Reseting candidate verifications...</Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <CircularProgress variant="determinate" value={progress} /> */}
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography>
                All the candidates have been moved to the apllications' section
                {sendEmail && " and an invitation email has been sent to the candidates"}.
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle>Convert Candidates</DialogTitle>
      <DialogContent sx={{ minHeight: "100px", width: "700px" }}>
        {getActivePage()}

        <Snackbar open={openSnackError} autoHideDuration={1000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
            The server encountered an error.
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetVerificationDialog;
